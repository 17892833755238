export const url = {
  deploy: "/tokens/deploy",
  deployStatus: "/tokens/deploy/status/",
  tokenInfo: "/tokens/info",
  allTokens: "/tokens",
  bridgeToken: "/tokens/bridge",
  bridgeStatus: "/tokens/bridge/status/",
  nftbridgeStatus: "/nft/bridge/status/",
  nftInfo: "/nft/info",
  deploynft: "/nft/deploy",
  nftdeployStatus: "/nft/deploy/status/",
  nftSolanaMintStatus: "/nft/mint/solananft/",
  nftCollection: '/nft/collection/',
  allNFTs: "/nft",
  bridgeNFT: "/nft/bridge",
  explorer: '/explorer/links',
  axelarBridgeEstimates: '/bridge-sdk/axelar/getFee',
  // moralisEndpoint: 'https://deep-index.moralis.io/api/v2/nft/'
  moralisEndpoint: 'https://deep-index.moralis.io/api/v2/'
};
