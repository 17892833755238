import { Box, Flex } from "components/Box";
import Button from "components/Button";
import Spinner from "components/SpinnerCircle";
import { Text } from "components/Text";
import { ChainArray, inputStyles } from "config/constants";
import {
    BridgeNFTResponse, CATType,
} from "config/types";
import { useEffect, useMemo, useState } from "react";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import {
    getAllNFTs, getNFTBridgeEstimates,
} from "services/nft.service";
import {
    useAccount,
    useBalance,
    useFeeData,
    useNetwork,
    usePrepareSendTransaction,
    useProvider,
    useSendTransaction,
    useSigner,
    useSwitchNetwork,
    useWaitForTransaction,
} from "wagmi";
import useTheme from "../../hooks/useTheme";
import { InnerSection, MainContainer } from "../Bridge/styles";
import { NFTinput } from "./styles";
import NFTmodal from "components/NFTModal";
import {
    RoutingEngineFacets,
    catERC721,
} from "config/abi/catERC721";
import { BigNumber, ethers } from "ethers";
import { payload721ABI } from "../Bridge/constants/initial-states";
import { EVM_CHAIN_ID_TO_CONVENTION, solanaSupportiveChains } from "config/constants/chains";
import Web3 from "web3";
import { useModal } from "widgets/Modal";
import BridgeModal from "../Bridge/components/BridgeModal";
import { getAxelarFee, ListenToLogs } from "../Bridge/utils";
import { handleDecimals, increaseGasFee, toastMessage } from "utils";
import useSolanaBalance from "components/hooks/useSolanaBalance";
import { useSolanaConnection, useSolanaWallet } from "contexts/SolanaWalletContext";
import { Transaction } from "@solana/web3.js";
import { ADDRESS_TXN_EXPLORER_LINK } from "config/constants/endpoints";

type SelectOptions = {
    label: string;
    value: number;
    icon: any;
    address: `0x${string}`;
    genericTokenAddress: `0x${string}`;
};

const BridgeNFT = () => {
    const { theme } = useTheme();
    const [supportedBlockChains, setSupportedBlockChains] = useState<Array<SelectOptions>>([]);
    const [selectedToChain, setSelectedToChain] = useState<SelectOptions>();
    const [selectedFromChain, setSelectedFromChain] = useState<SelectOptions>();
    const [nfts, setNfts] = useState<Array<BridgeNFTResponse>>([]);
    const [isFetchEstimates, setIsFetchEstimates] = useState<boolean>(false);
    const [selectedChainNetworks, setSelectedChainNetworks] = useState([])
    const [nftId, setNftId] = useState<string>(undefined)
    const { data: signer } = useSigner();
    const [estimatedValueInWei, setEstimatedValueInWei] = useState(null);
    const [isTransactionOccuring, setIsTransactionOccuring] = useState(false);
    const [txHash, setTxHash] = useState("");
    const [explorerLink, setExplorerLink] = useState("");
    const [bridgeEstimate, setBridgeEstimate] = useState<any>();
    const [nftNetwork, setNftNetwork] = useState<number>(null)
    const { solanaBalanceFormatted, solanaBalance } = useSolanaBalance();
    const { publicKey } = useSolanaWallet();
    const [solTransaction, setSolTransaction] = useState<string>("")
    const { connection: solanaConnection } = useSolanaConnection();
    const { sendTransaction: sendSolanaTx, signTransaction: signSolanaTx } = useSolanaWallet();
    const [solBridgeResponse, setSolBridgeResponse] = useState<any>(null);
    const { data: gasFee } = useFeeData({ chainId: selectedFromChain?.value });
    const [prepareTx, setPrepareTx] = useState<any>(null);

    const isFromChainSolana = useMemo(() => solanaSupportiveChains.has(selectedFromChain?.value), [selectedFromChain?.value]);
    const isToChainSolana = useMemo(() => solanaSupportiveChains.has(selectedToChain?.value), [selectedToChain?.value]);

    const { config } = usePrepareSendTransaction({
        request: {
            to: prepareTx?.to,
            value: prepareTx?.value,
            data: prepareTx?.data,
            gasPrice: prepareTx?.gasFee,
        },
    });

    const {
        data: txData,
        isLoading,
        isSuccess,
        sendTransaction,
        error
    } = useSendTransaction(config);

    const { isLoading: isWaitLoading, isSuccess: isTxSuccess } =
        useWaitForTransaction({
            hash: txData?.hash,
        });

    const { Option } = components;
    const IconOption = (props) => (
        <Option className="chain-options" {...props}>
            <Box className="svg-icon" mr={'5px'}>{props.data.icon}</Box>
            {props.data.label}
        </Option>
    );

    const SingleValue = (props) => (
        <Flex alignItems={'center'}>
            <Box className="select-svg-icon" mt={'7px'} mr={'5px'}>{props.data.icon}</Box>
            {props.data.label}
        </Flex>
    );

    const { address, isConnected } = useAccount();
    const { chain } = useNetwork();
    const { switchNetworkAsync } = useSwitchNetwork();
    const provider: any = useProvider();

    const { data: balanceData } = useBalance({
        address,
        chainId: selectedFromChain?.value,
    });

    const isValid: boolean =
        isConnected && +nftId >= 0 &&
            selectedFromChain &&
            selectedToChain
            ? true
            : false;

    const [showBridgeModal, onDismissBridgeModal] = useModal(
        <BridgeModal
            handleDismiss={() => {
                setExplorerLink("");
                setTxHash("");
                setNftId(null);
                setSelectedToChain(null);
                onDismissBridgeModal();
            }}
            fromChain={selectedFromChain}
            toChain={selectedToChain}
            explorerLink={explorerLink}
            txHash={txHash}
        />,
        true
    );

    useEffect(() => {
        fetchNFTs();
    }, []);

    useEffect(() => {
        if (selectedChainNetworks) {
            const availableChains = selectedChainNetworks.map((x) => {
                return {
                    chainId: x.chainId,
                    address: x.address,
                    genericTokenAddress: x?.genericTokenAddress,
                };
            });
            const selectedNftChains = [];
            ChainArray.forEach((item) => {
                for (let chain of availableChains) {
                    if (chain.chainId === item.chainId) {
                        selectedNftChains.push({
                            value: item.chainId,
                            label: item.name,
                            icon: item.icon,
                            address: chain.address,
                            genericTokenAddress: chain.genericTokenAddress,
                        });
                    }
                }
                return selectedNftChains;
            });
            setSelectedFromChain(null);
            setSelectedToChain(null);
            setSupportedBlockChains(selectedNftChains);
        }
    }, [selectedChainNetworks]);

    useEffect(() => {
        if (chain?.id) {
            const defaultChain: SelectOptions = supportedBlockChains.find(data => data.value === nftNetwork)
            setSelectedFromChain(defaultChain)
        }
    }, [nftId, chain])

    useEffect(() => {
        fetchEstimates();
    }, [isConnected, selectedFromChain, selectedToChain]);

    useEffect(() => {
        if (isValid === false) {
            setBridgeEstimate(undefined);
        }
    }, [isValid]);

    const fetchNFTs = async () => {
        try {
            const res = await getAllNFTs();
            const nftRes = res.nfts.map((collection) => {
                return {
                    baseUri: collection.baseUri,
                    name: collection.name,
                    ticker: collection.symbol,
                    networks: collection.networks,
                    owner: collection.owner,
                    imageUrl: collection.imageUrl,
                    tokenMintChainId: collection.tokenMintChainId
                };
            });
            setNfts(nftRes);
        } catch (err) {
            console.log(err);
            setNfts([]);
        }
    };

    const checkSwitchNetwork = async (choice: SelectOptions) => {
        if (chain && choice && chain?.id !== choice?.value) {
            try {
                await switchNetworkAsync(choice.value);
                setNftId(null)
            } catch (error) {
                toastMessage("User rejected the transaction.", "error");
                return;
            }
        }
        setSelectedFromChain(choice);
    }

    const getICATERC721Payload = () => {
        return {
            uri: `${nfts[0]?.baseUri}${nftId}`,
            tokenId: nftId,
            destTokenAddress: ethers.utils.hexZeroPad(selectedToChain?.address, 32),
            destTokenChain: EVM_CHAIN_ID_TO_CONVENTION[selectedToChain?.value],
            destUserAddress: ethers.utils.hexZeroPad(address, 32),
            sourceTokenAddress: ethers.utils.hexZeroPad(
                selectedFromChain?.address,
                32
            ),
            sourceTokenChain: EVM_CHAIN_ID_TO_CONVENTION[selectedFromChain?.value],
            sourceUserAddress: ethers.utils.hexZeroPad(address, 32),
        }
    }

    const fetchEstimates = async () => {
        try {
            if (isValid) {
                setIsFetchEstimates(true);
                if (solanaSupportiveChains.has(selectedFromChain?.value) || solanaSupportiveChains.has(selectedToChain?.value)) {
                    const isSolProxyToken = selectedChainNetworks.find(res => res.type == CATType.CATProxyType)
                    const solCollectionAddress = selectedChainNetworks?.find(res => solanaSupportiveChains.has(res.chainId)).solanaCollectionAddress
                    const bridgeEstimates = await getNFTBridgeEstimates(
                        selectedFromChain?.value,
                        selectedFromChain?.address,
                        selectedToChain?.value,
                        selectedToChain?.address,
                        isFromChainSolana ? publicKey.toString() : address,
                        isToChainSolana ? publicKey.toString() : address,
                        nftId?.toString(),
                        isSolProxyToken ? solCollectionAddress : null
                    )
                    if (!isFromChainSolana && isToChainSolana) {
                        bridgeEstimates.transactions = bridgeEstimates?.transactions.map((x) => {
                            return {
                                ...x,
                                isSigned: false,
                            };
                        });
                        const increasedGasFee = increaseGasFee(gasFee?.gasPrice, 10)
                        bridgeEstimates.gasFee = increasedGasFee
                    }
                    let estimatedWei: BigNumber = bridgeEstimates?.totalGasFeeInTokenSourceChainInWei;
                    setSolBridgeResponse(bridgeEstimates)
                    setBridgeEstimate(bridgeEstimates?.totalGasFeeInTokenSourceChain)
                    setEstimatedValueInWei(estimatedWei);
                    setSolTransaction(bridgeEstimates?.transactions)
                    setIsFetchEstimates(false);
                }
                else {
                    const contract = new ethers.Contract(selectedFromChain?.address, catERC721, signer);
                    const routingEngineAddress = await contract.routingEngine();
                    const protocolPriority = await contract.getProtocolPriority();
                    const gasLimit = await contract.getGasLimit();

                    const routingEngine = new ethers.Contract(
                        routingEngineAddress,
                        RoutingEngineFacets,
                        signer
                    );

                    const payload = getICATERC721Payload()
                    const encodedPayload = ethers.utils.defaultAbiCoder.encode(
                        [
                            {
                                components: payload721ABI,
                                name: "catPayload",
                                type: "tuple",
                            },
                        ] as ethers.utils.ParamType[],
                        [payload]
                    );

                    const fee = await routingEngine.getEstimatedFee(
                        encodedPayload,
                        protocolPriority,
                        EVM_CHAIN_ID_TO_CONVENTION[selectedToChain?.value],
                        ethers.utils.hexZeroPad(address, 32),
                        ethers.utils.hexZeroPad(selectedToChain?.address, 32),
                        gasLimit
                    );
                    let estimatedWei: BigNumber = fee.add(fee.mul(10).div(100));
                    if (protocolPriority.map((p) => p.toString()).includes("1")) {
                        estimatedWei = await getAxelarFee(estimatedWei, selectedFromChain?.value, selectedToChain?.value);
                    }
                    setEstimatedValueInWei(estimatedWei);
                    setBridgeEstimate(ethers?.utils?.formatEther(estimatedWei?.toString()));
                    setIsFetchEstimates(false);
                }
            }
        } catch (error: any) {
            toastMessage(error.message, "error")
            setBridgeEstimate(undefined);
            setIsFetchEstimates(false);
        }
    };

    const signTransaction = async () => {
        if (isFromChainSolana) {
            try {
                setIsTransactionOccuring(true);
                const {
                    context: { slot: minContextSlot },
                    value: { blockhash, lastValidBlockHeight },
                } = await solanaConnection.getLatestBlockhashAndContext();
                const tx = Transaction.from(
                    Buffer.from(solTransaction, "base64")
                );
                // const sign = await signSolanaTx(deployEstimate.transaction);

                const signature = await sendSolanaTx(tx, solanaConnection);

                const confirmedTx = await solanaConnection.confirmTransaction({
                    blockhash,
                    lastValidBlockHeight,
                    signature,
                })
                setTxHash(signature);
                setExplorerLink(`${ADDRESS_TXN_EXPLORER_LINK[selectedFromChain?.value]}${signature}${process.env.REACT_APP_CHAINS_ENV === "testnet" && "?cluster=devnet"}`);
                setIsTransactionOccuring(false);
                return;
            }
            catch (e) {
                setIsTransactionOccuring(false);
            }
        }
        if (!isFromChainSolana && isToChainSolana) {
            setIsTransactionOccuring(true);
            const tx = solBridgeResponse?.transactions.find((x) => x.isSigned === false);
            if (tx) {
                const increasedFee = increaseGasFee(gasFee?.gasPrice, 10)
                tx.gasFee = increasedFee
                setPrepareTx(tx);
            }
            else {
                setTxHash(txData?.hash)
                const txLink = `${ADDRESS_TXN_EXPLORER_LINK[selectedFromChain?.value]}${txData?.hash}`
                setExplorerLink(txLink)
                setPrepareTx(null)
                setIsTransactionOccuring(false);
            }
            return;
        }
        else {
            if (chain?.id !== selectedFromChain.value) {
                const network = await switchNetworkAsync(selectedFromChain.value)
                    .then((res) => {
                        sendTx();
                    })
                    .catch(() => {
                        toastMessage("Error while changing network", "error");
                    });
            } else {
                sendTx();
            }
            return;
        }
    };

    useEffect(() => {
        if (isValid && prepareTx && prepareTx?.data && sendTransaction) {
            sendTransaction?.();
        }
    }, [prepareTx, sendTransaction]);

    useEffect(() => {
        if (isTxSuccess) {
            solBridgeResponse.transactions = solBridgeResponse?.transactions?.map((x) => {
                if (x.clientId === prepareTx.clientId) {
                    x.isSigned = true;
                }
                return {
                    ...x,
                };
            });
            setSolBridgeResponse(solBridgeResponse);
            signTransaction();
        }
        else if (error) {
            toastMessage(error?.message, "error")
        }
    }, [isTxSuccess, error]);

    const approveTransaction = async (sourceChain: SelectOptions) => {
        const contract = new ethers.Contract(
            sourceChain.genericTokenAddress,
            catERC721,
            signer
        );
        try {
            const isApprovedForAll = await contract.isApprovedForAll(address, selectedFromChain?.address)
            if (isApprovedForAll) { return true }
            else {
                const approval = await contract.setApprovalForAll(
                    sourceChain.address,
                    true
                )

                await approval.wait(1);
                return true;
            }
        } catch (error: any) {
            toastMessage("User rejected the transaction.", "error")
            setIsTransactionOccuring(false);
            return false;
        }
    };

    const sendTx = async () => {
        setIsTransactionOccuring(true);
        try {
            const web3 = new Web3(provider.connection.url);

            if (selectedFromChain?.genericTokenAddress !== null) {
                const response = await approveTransaction(selectedFromChain);
                if (!response) return;
            }
            let contract = new ethers.Contract(selectedFromChain?.address, catERC721, signer);

            if (estimatedValueInWei === null) {
                setIsTransactionOccuring(false);
                return;
            }

            const estimates = await contract.bridgeOut(
                nftId,
                EVM_CHAIN_ID_TO_CONVENTION[selectedToChain?.value],
                ethers.utils.hexZeroPad(address, 32),
                {
                    value: estimatedValueInWei.toString(),
                }
            );
            await estimates.wait(2);

            let tx = await web3.eth.getTransactionReceipt(estimates?.hash);
            const { txLink, status, hash } = await ListenToLogs(tx, estimates, provider);
            setTxHash(hash);
            setExplorerLink(txLink);
            setIsTransactionOccuring(status);
        } catch (error: any) {
            setIsTransactionOccuring(false);
            if (error.code === "ACTION_REJECTED") {
                toastMessage("User rejected the transaction.", "error")
                return
            }
            toastMessage("The selected chain is not supported by the underlying bridge.", "error")
        }
    }

    useEffect(() => {
        if (explorerLink) {
            showBridgeModal();
        }
    }, [explorerLink]);

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const openModal = () => {
        if (isConnected) {
            setModalIsOpen(true)
            setNftId(null)
        } else {
            toast.error(`Please connect your wallet.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                theme: "dark",
            });
        }
    }

    return (
        <MainContainer>
            <InnerSection>
                {<NFTmodal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} setNftId={setNftId} nfts={nfts} setSelectedChainNetworks={setSelectedChainNetworks} setNftNetwork={setNftNetwork} />}
                <Flex justifyContent={"space-around"}>
                    <Text
                        fontFamily={theme.fonts.primary}
                        fontWeight={theme.fonts.semiBold}
                        fontSize={"32px"}
                    >
                        Bridge
                    </Text>
                </Flex>
                <Flex justifyContent={"space-around"} mt={"21px"}>
                    <Text
                        fontFamily={theme.fonts.primary}
                        fontStyle={"normal"}
                        fontWeight={theme.fonts.light}
                        fontSize={"14px"}
                    >
                        Migrate your NFT across chains
                    </Text>
                </Flex>
                <Flex mt={"10px"}>
                    <Flex flexDirection={"column"} width={"100%"} mt={"20px"}>
                        <NFTinput
                            onClick={() => openModal()}
                        >
                            <Flex ml={'5px'}>
                                {+nftId >= 0 && nftId != null ? `NFT ID: ${nftId}` : 'Select NFT'}
                            </Flex>
                        </NFTinput>
                    </Flex>
                </Flex>
                <Flex flexWrap={"wrap"}>
                    <Flex mt={"10px"} width={"50%"} flexDirection={"column"}>
                        <Flex mt={"16px"} ml={"12px"} mb={"8px"}>
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                fontSize={"14px"}
                            >
                                From
                            </Text>
                        </Flex>
                        <Flex width={"90%"}>
                            <Select
                                isClearable
                                className="select-main-container"
                                name="form-field-name"
                                placeholder={"Select"}
                                options={supportedBlockChains.filter(
                                    (x) => x.value !== selectedToChain?.value
                                )}
                                value={selectedFromChain}
                                onChange={(choice) => solanaSupportiveChains.has(choice?.value) ? setSelectedFromChain(choice) : checkSwitchNetwork(choice)}
                                components={{
                                    Option: IconOption,
                                    SingleValue,
                                    IndicatorSeparator: () => null,
                                }}
                                styles={inputStyles}
                            />
                        </Flex>
                    </Flex>

                    <Flex mt={"10px"} width={"50%"} flexDirection={"column"}>
                        <Flex mt={"16px"} ml={"10px"} mb={"8px"}>
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                fontSize={"14px"}
                            >
                                To
                            </Text>
                        </Flex>
                        <Flex>
                            <Select
                                isClearable
                                className="select-main-container"
                                name="form-field-name"
                                options={supportedBlockChains.filter(
                                    (x) => x.value !== selectedFromChain?.value
                                )}
                                placeholder={"Select"}
                                value={selectedToChain}
                                onChange={(choice) => setSelectedToChain(choice)}
                                components={{
                                    Option: IconOption,
                                    SingleValue,
                                    IndicatorSeparator: () => null,
                                }}
                                styles={inputStyles}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex flexWrap={"wrap"}>
                    <Flex mt={"10px"} width={"100%"} flexDirection={"column"}>
                        <Flex
                            mt={"16px"}
                            mb={"0px"}
                            justifyContent={"space-between"}
                        >
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                color={theme.colors.text}
                                fontSize={"14px"}
                            >
                                Required Balance:
                            </Text>
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                fontSize={"14px"}
                                color={
                                    isValid
                                        ? (solanaSupportiveChains.has(selectedFromChain?.value) ? (+solanaBalance) : (parseFloat(balanceData?.formatted)) > +bridgeEstimate)
                                            ? theme.colors.success
                                            : theme.colors.failure
                                        : theme.colors.textDisabled
                                }
                            >
                                {isConnected && isFetchEstimates ? (
                                    <Spinner radius={8} />
                                ) : (isConnected &&
                                    isValid && bridgeEstimate) ? (
                                    `${handleDecimals(+bridgeEstimate)} ${isFromChainSolana ? 'SOL' : balanceData?.symbol}`
                                ) : (
                                    "-"
                                )}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex mt={"8px"} width={"100%"} flexDirection={"column"}>
                        <Flex
                            mt={"16px"}
                            mb={"0px"}
                            justifyContent={"space-between"}
                        >
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                color={theme.colors.text}
                                fontSize={"14px"}
                            >
                                Available Balance:
                            </Text>
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                color={theme.colors.textDisabled}
                                fontSize={"14px"}
                            >
                                {isConnected && isFetchEstimates ? (
                                    <Spinner radius={8} />
                                ) : publicKey && solanaSupportiveChains.has(selectedFromChain?.value) ? solanaBalanceFormatted : isConnected && balanceData?.formatted ? (
                                    `${parseFloat(balanceData?.formatted).toFixed(4)} ${balanceData.symbol
                                    }`
                                ) : (
                                    "-"
                                )}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex justifyContent={"space-around"} mt={"21px"}>
                    <Box width={'100%'}>
                        <Button
                            height={"44px"}
                            width={"478px"}
                            type={"button"}
                            variant={"tertiary"}
                            onClick={signTransaction}
                            disabled={
                                (solanaSupportiveChains.has(selectedFromChain?.value) ? (+solanaBalance) : (parseFloat(balanceData?.formatted))) <
                                parseFloat(
                                    bridgeEstimate
                                ) ||
                                bridgeEstimate === undefined ||
                                isValid === false ||
                                isTransactionOccuring
                            }
                        >
                            <Flex justifyContent={"center"}>
                                <Text
                                    fontWeight={theme.fonts.semiBold}
                                    fontSize={"14px"}
                                    ml={"6px"}
                                >
                                    {isTransactionOccuring ? (
                                        <Spinner radius={8} />
                                    ) : (
                                        "Migrate"
                                    )}
                                </Text>
                            </Flex>
                        </Button>
                    </Box>
                </Flex>
            </InnerSection>
        </MainContainer>
    );
};

export default BridgeNFT;
