import { Box, Flex } from "components/Box";
import Button from "components/Button";
import Spinner from "components/SpinnerCircle";
import { Text } from "components/Text";
import { ChainArray, inputStyles } from "config/constants";
import { BridgeTokenResponse, BridgeTransaction, CATType } from "config/types";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { getAllTokens, getBridgeEstimates } from "services/token.service";
import {
  noExponents,
  handleDecimals,
  toastMessage,
  solanaTokenBalance,
  increaseGasFee,
} from "utils";
import {
  useAccount,
  useBalance,
  useNetwork,
  usePrepareSendTransaction,
  useSendTransaction,
  useWaitForTransaction,
  useFeeData,
  useSigner,
  useProvider,
  Address,
  useSwitchNetwork,
} from "wagmi";
import useTheme from "../../hooks/useTheme";
import { Input } from "../DeployTokens/styles";
import { InnerSection, MainContainer } from "./styles";
import {
  RoutingEngineEvents,
  RoutingEngineFacets,
  catERC20,
} from "../../../config/abi/catERC721";
import { BigNumber, ethers } from "ethers";
import {
  EVM_CHAIN_ID_TO_CONVENTION,
  EVM_CHAIN_ID_TO_TICKER_AXELAR,
  EVM_CHAIN_TO_AXELAR_NAMES,
  evmSupportiveChains,
  solanaSupportiveChains,
} from "config/constants/chains";
import Web3 from "web3";
import BridgeModal from "./components/BridgeModal";
import { useModal } from "widgets/Modal";
import { BRIDGES_URL } from "config/constants/supportedBridges";
import { ICATERC20 } from "config/abi/types";
import {
  getAxelarEstimatedFee,
  getExplorerLink,
} from "services/explorer.service";
import {
  payloadABI,
  selectedTokenInitialState,
} from "./constants/initial-states";
import { ListenToLogs } from "./utils";
import { useSolanaConnection, useSolanaWallet } from "contexts/SolanaWalletContext";
import useSolanaBalance from "components/hooks/useSolanaBalance";
import { Transaction, PublicKey } from "@solana/web3.js";
import { getAssociatedTokenAddressSync } from "@solana/spl-token"
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { ADDRESS_TXN_EXPLORER_LINK } from "config/constants/endpoints";

type SelectOptions = {
  label: string;
  value: number;
  icon: any;
  address: Address;
  genericTokenAddress: Address;
  solanaTokenAddress: Address;
};

const IconOption = (props) => {
  const { Option } = components;
  return (
    <Option className="chain-options" {...props}>
      <Box className="svg-icon" mr={"5px"}>
        {props.data.icon}
      </Box>
      {props.data.label}
    </Option>
  );
};

const SingleValue = (props) => (
  <Flex alignItems={"center"}>
    <Box className="select-svg-icon" mt={"7px"} mr={"5px"}>
      {props.data.icon}
    </Box>
    {props.data.label}
  </Flex>
);

const Bridge = () => {
  const { theme } = useTheme();
  const history = useHistory();
  const [supportedBlockChains, setSupportedBlockChains] = useState<
    Array<SelectOptions>
  >([]);
  const [amount, setAmount] = useState<string | null>("");
  const [selectedToken, setSelectedToken] = useState<BridgeTokenResponse>(
    selectedTokenInitialState
  );
  const [selectedToChain, setSelectedToChain] =
    useState<SelectOptions | null>();
  const [selectedFromChain, setSelectedFromChain] =
    useState<SelectOptions | null>();
  const [tokens, setTokens] = useState<Array<BridgeTokenResponse>>([]);
  const [isFetchEstimates, setIsFetchEstimates] = useState<boolean>(false);
  const provider: any = useProvider();
  const [txHash, setTxHash] = useState("");
  const [explorerLink, setExplorerLink] = useState("");
  const [isTransactionOccuring, setIsTransactionOccuring] = useState(false);
  const [isChainChanged, setIsChainChanged] = useState<boolean>(false);
  const [bridgeEstimate, setBridgeEstimate] = useState<any>();
  const [prepareTx, setPrepareTx] = useState<BridgeTransaction>(null);
  const [solTransaction, setSolTransaction] = useState<string>("")
  const [bridgeFee, setBridgeFee] = useState<string>()

  const { switchNetworkAsync } = useSwitchNetwork();
  const [showBridgeModal, onDismissBridgeModal] = useModal(
    <BridgeModal
      handleDismiss={() => {
        setExplorerLink("");
        setTxHash("");
        setAmount("");
        onDismissBridgeModal();
        setSelectedToChain(null);
        setSelectedFromChain(null);
      }}
      fromChain={selectedFromChain}
      toChain={selectedToChain}
      explorerLink={explorerLink}
      txHash={txHash}
    />,
    true
  );
  const { data: signer, isLoading: isSignerLoading } = useSigner();

  const [estimatedValueInWei, setEstimatedValueInWei] = useState(null);

  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { data: gasFee } = useFeeData({ chainId: selectedFromChain?.value });

  const { data: balanceData } = useBalance({
    address,
    chainId: selectedFromChain?.value,
  });

  const { connected: solanaConnected, publicKey, sendTransaction: sendSolanaTx } = useSolanaWallet();
  const { solanaBalance, solanaBalanceFormatted } = useSolanaBalance();
  const { connection: solanaConnection } = useSolanaConnection();
  const { data: transferChainBalanceData, isLoading: isBalanceLoading } =
    useBalance({
      address,
      chainId: selectedFromChain?.value,
      token:
        selectedFromChain?.genericTokenAddress || selectedFromChain?.address,
    });
  const [solTokenBalance, setSolTokenBalance] = useState<string>("")

  const isFromChainSolana = useMemo(() => solanaSupportiveChains.has(selectedFromChain?.value), [selectedFromChain?.value]);
  const isToChainSolana = useMemo(() => solanaSupportiveChains.has(selectedToChain?.value), [selectedToChain?.value]);

  const isValid: boolean =
    !!selectedToken?.decimals &&
    !!selectedFromChain &&
    !!selectedToChain &&
    !!amount && amount !== '0' &&
    !!(evmSupportiveChains.has(selectedFromChain?.value) && evmSupportiveChains.has(selectedToChain?.value) ? isConnected : (isConnected && solanaConnected))

  const { config } = usePrepareSendTransaction({
    request: {
      to: prepareTx?.to,
      value: prepareTx?.value,
      data: prepareTx?.data,
      gasPrice: prepareTx?.gasFee,
    },
  });
  const {
    data: txData,
    isLoading,
    sendTransaction,
    error
  } = useSendTransaction(config);

  const { isLoading: isWaitLoading, isSuccess: isTxSuccess } =
    useWaitForTransaction({
      hash: txData?.hash,
    });

  useEffect(() => {
    if (isChainChanged === true && chain?.id === selectedFromChain.value) {
      setIsChainChanged(false);
    }
  }, [isChainChanged, chain]);

  useEffect(() => {
    fetchTokens();
  }, []);

  useEffect(() => {
    if (selectedToken?.networks) {
      const availableChains = selectedToken.networks.map((x) => {
        return {
          chainId: x.chainId,
          address: x.address,
          genericTokenAddress: x?.genericTokenAddress,
          solanaTokenAddress: x?.solanaTokenAddress
        };
      });
      const selectedTokenChains = [];
      ChainArray.forEach((item) => {
        for (let chain of availableChains) {
          if (chain.chainId === item.chainId) {
            selectedTokenChains.push({
              value: item.chainId,
              label: item.name,
              icon: item.icon,
              address: chain.address,
              genericTokenAddress: chain.genericTokenAddress,
              solanaTokenAddress: chain.solanaTokenAddress
            });
          }
        }
        return selectedTokenChains;
      });
      setSelectedFromChain(null);
      setSelectedToChain(null);
      setSupportedBlockChains(selectedTokenChains);
    }
  }, [selectedToken]);

  useEffect(() => {
    let delayDebounceFn;
    if (isValid && !isSignerLoading) {
      delayDebounceFn = setTimeout(() => {
        fetchEstimates();
      }, 800)
    }
    return () => clearTimeout(delayDebounceFn)
  }, [isConnected, selectedFromChain, selectedToChain, amount, isSignerLoading]);

  useEffect(() => {
    if (isValid === false) {
      setBridgeEstimate(undefined);
      setBridgeFee(undefined);
    }
  }, [isValid]);

  useEffect(() => {
    if (isValid && prepareTx && prepareTx?.data && sendTransaction) {
      sendTransaction?.();
    }
  }, [prepareTx, sendTransaction]);

  useEffect(() => {
    if (isTxSuccess) {
      bridgeEstimate.transactions = bridgeEstimate?.transactions.map((x) => {
        if (x.clientId === prepareTx.clientId) {
          x.isSigned = true;
        }
        return {
          ...x,
        };
      });
      setBridgeEstimate(bridgeEstimate);
      signTransaction();
    }
    else if (error) {
      toastMessage(error?.message, "error")
    }
  }, [isTxSuccess, error]);

  const fetchTokens = async () => {
    try {
      const res = await getAllTokens();
      const tokenRes = res.tokens.map((token) => {
        return {
          value: token.salt,
          label: token.symbol,
          networks: token.networks,
          owner: token.owner,
          decimals: token.decimals,
        };
      });
      setTokens(tokenRes);
    } catch (err) {
      setTokens([]);
    }
  };

  const getICATERC20Payload = (amount: string, decimals: any) => {
    return {
      amount: amount,
      destTokenAddress: ethers.utils.hexZeroPad(selectedToChain.address, 32),
      destTokenChain: EVM_CHAIN_ID_TO_CONVENTION[selectedToChain?.value],
      destUserAddress: ethers.utils.hexZeroPad(address, 32),
      sourceTokenAddress: ethers.utils.hexZeroPad(
        selectedFromChain.address,
        32
      ),
      sourceTokenChain: EVM_CHAIN_ID_TO_CONVENTION[selectedFromChain?.value],
      sourceUserAddress: ethers.utils.hexZeroPad(address, 32),
      tokenDecimals: decimals,
    };
  };

  const getAxelarFee = async (estimatedWei: BigNumber) => {
    try {
      let {
        data: { fee: axelarFee },
      }: any = await getAxelarEstimatedFee(
        EVM_CHAIN_TO_AXELAR_NAMES[selectedFromChain.value],
        EVM_CHAIN_TO_AXELAR_NAMES[selectedToChain.value],
        EVM_CHAIN_ID_TO_TICKER_AXELAR[selectedFromChain.value]
      );
      if (!axelarFee) {
        return estimatedWei;
      }
      axelarFee = BigNumber.from(axelarFee).add(
        BigNumber.from(axelarFee).mul(10).div(100)
      );
      return BigNumber.from(axelarFee).gt(estimatedWei)
        ? axelarFee
        : estimatedWei;
    } catch (error) {
      return estimatedWei;
    }
  };

  const checkIfChainSwitchIsNeeded = async (choice) => {
    if (solanaSupportiveChains.has(choice?.value)) {
      setSelectedFromChain(choice);
    }
    else {
      if (chain && choice && chain?.id !== choice?.value) {
        try {
          await switchNetworkAsync(choice.value);
        } catch (error) {
          toastMessage("User rejected the transaction.", "error");
          return;
        }
      }
      setSelectedFromChain(choice);
    }
  };

  const fetchEstimates = async () => {
    if (+amount > (isFromChainSolana ? +solTokenBalance : +transferChainBalanceData?.formatted)) {
      toastMessage(`Your token balance is low.`, "error");
      return;
    }
    setIsFetchEstimates(true);
    try {
      if (isFromChainSolana || isToChainSolana) {
        const isSolProxyToken = selectedToken?.networks?.find(res => res.type === CATType.CATProxyType)
        const bridgeEstimates = await getBridgeEstimates(
          selectedFromChain?.value,
          selectedFromChain?.address,
          selectedToChain?.value,
          selectedToChain?.address,
          isFromChainSolana ? publicKey.toString() : address,
          isToChainSolana ? publicKey.toString() : address,
          (BigNumber.from(amount).mul(BigNumber.from(10).pow(selectedToken?.decimals))).toString(),
          isSolProxyToken ? selectedFromChain?.solanaTokenAddress : null
        )
        if (!isFromChainSolana) {
          bridgeEstimates.transactions = bridgeEstimates?.transactions.map((x) => {
            return {
              ...x,
              isSigned: false,
              clientId: `CAT-${Math.random()}`,
            };
          });
        }
        else {
          setSolTransaction(bridgeEstimates?.transactions)
        }
        setBridgeEstimate(bridgeEstimates)
        setBridgeFee(bridgeEstimates?.totalGasFeeInTokenSourceChain)
      }
      else {
        const amountInWei = noExponents(
          Math.floor(+amount * Math.pow(10, selectedToken.decimals)).toString()
        );

        const contract = new ethers.Contract(selectedFromChain.address, catERC20, signer);
        const routingEngineAddress = await contract.routingEngine();
        const protocolPriority = await contract.getProtocolPriority();
        const gasLimit = await contract.getGasLimit();
        const decimals = await contract.decimals();

        const routingEngine = new ethers.Contract(
          routingEngineAddress,
          RoutingEngineFacets,
          signer
        );

        const payload: ICATERC20.CATPayloadStruct = getICATERC20Payload(
          amountInWei,
          decimals
        );

        const encodedPayload = ethers.utils.defaultAbiCoder.encode(
          [
            {
              components: payloadABI,
              name: "catPayload",
              type: "tuple",
            },
          ] as ethers.utils.ParamType[],
          [payload]
        );

        const fee = await routingEngine.getEstimatedFee(
          encodedPayload,
          protocolPriority,
          EVM_CHAIN_ID_TO_CONVENTION[selectedToChain?.value],
          ethers.utils.hexZeroPad(address, 32),
          ethers.utils.hexZeroPad(selectedToChain.address, 32),
          gasLimit
        );

        let estimatedWei: BigNumber = fee.add(fee.mul(10).div(100));
        if (protocolPriority.map((p) => p.toString()).includes("1")) {
          estimatedWei = await getAxelarFee(estimatedWei);
        }

        setEstimatedValueInWei(estimatedWei);

        setBridgeEstimate(ethers.utils.formatEther(estimatedWei.toString()));
        setBridgeFee(ethers.utils.formatEther(estimatedWei.toString()));
      }
    } catch (error: any) {
      console.error(error.message)
      toastMessage("Error while fetching fee estimates.", "error")
    } finally {
      setIsFetchEstimates(false)
    }
  };

  useEffect(() => {
    if (explorerLink) {
      showBridgeModal();
    }
  }, [explorerLink]);

  const approveTransaction = async (sourceChain: SelectOptions, amountInWei: string) => {
    const contract = new ethers.Contract(
      sourceChain.genericTokenAddress,
      catERC20,
      signer
    );

    try {
      const isApprovedForAll = await contract.allowance(address, sourceChain?.address)
      if (BigNumber.from(isApprovedForAll).gt(BigNumber.from(amountInWei))) { return true }
      const approval = await contract.approve(
        sourceChain.address,
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      );

      await approval.wait(1);
      return true
    } catch (error) {
      toastMessage("User rejected the transaction.", "error")
      setIsTransactionOccuring(false);
      return false
    }
  };

  const signTransaction = async () => {
    if (isFromChainSolana || isToChainSolana) {
      try {
        setIsTransactionOccuring(true);
        if (!isFromChainSolana) {
          const tx = bridgeEstimate?.transactions.find((x) => x.isSigned === false);
          if (tx) {
            const increasedFee = increaseGasFee(gasFee?.gasPrice, 10)
            tx.gasFee = increasedFee
            setPrepareTx(tx);
          }
          else {
            setTxHash(txData?.hash)
            const txLink = `${ADDRESS_TXN_EXPLORER_LINK[selectedFromChain?.value]}${txData?.hash}`
            setExplorerLink(txLink)
            setPrepareTx(null)
            setIsTransactionOccuring(false);
          }
        }
        else {
          const {
            context: { slot: minContextSlot },
            value: { blockhash, lastValidBlockHeight },
          } = await solanaConnection.getLatestBlockhashAndContext();

          const tx = Transaction.from(
            Buffer.from(solTransaction, "base64")
          );

          const signature = await sendSolanaTx(tx, solanaConnection);

          const confirmedTx = await solanaConnection.confirmTransaction({
            blockhash,
            lastValidBlockHeight,
            signature,
          });
          setTxHash(signature)
          const txLink = `${ADDRESS_TXN_EXPLORER_LINK[selectedFromChain?.value]}${signature}?cluster=devnet`
          setExplorerLink(txLink)
          setIsTransactionOccuring(false);
        }
      } catch (error: any) {
        setIsTransactionOccuring(false);
      }
    }
    else {
      if (+transferChainBalanceData.formatted < +amount) {
        toastMessage(`Your token balance is low.`, "error");
        return;
      }

      setIsTransactionOccuring(true);
      try {
        const web3 = new Web3(provider.connection.url);

        const sourceChain = supportedBlockChains.filter(
          (chain) => chain.value === selectedFromChain?.value
        );

        if (!sourceChain.length) {
          return;
        }
        const amountInWei = noExponents(
          Math.floor(+amount * Math.pow(10, selectedToken.decimals)).toString()
        );

        if (sourceChain[0].genericTokenAddress !== null) {
          const response = await approveTransaction(sourceChain[0], amountInWei);
          if (!response) {
            return
          }
        }
        let contract = new ethers.Contract(sourceChain[0].address, catERC20, signer);

        if (estimatedValueInWei === null) {
          setIsTransactionOccuring(false);
          return;
        }

        const estimates = await contract.bridgeOut(
          amountInWei,
          EVM_CHAIN_ID_TO_CONVENTION[selectedToChain?.value],
          ethers.utils.hexZeroPad(address, 32),
          {
            value: estimatedValueInWei.toString(),
          }
        );
        await estimates.wait(2);

        let tx = await web3.eth.getTransactionReceipt(estimates.hash);

        const { txLink, status, hash } = await ListenToLogs(tx, estimates, provider);
        setTxHash(hash);
        setExplorerLink(txLink);
        setIsTransactionOccuring(status);
        setIsTransactionOccuring(false);
      } catch (error: any) {
        setIsTransactionOccuring(false);
        console.error(error.message)
        if (error?.code === "ACTION_REJECTED") {
          toastMessage("User rejected the transaction.", "error")
          return
        }
        toastMessage("The selected chain is not supported by the underlying bridge.", "error")
      }
    }
  };

  useEffect(() => {
    const fetchTokenBalance = async () => {
      const tokenAccountInfo = await solanaConnection.getParsedTokenAccountsByOwner(publicKey, { mint: new PublicKey(selectedFromChain?.solanaTokenAddress) })
      const splTokenBalance = tokenAccountInfo.value[0].account.data.parsed.info.tokenAmount.amount;

      setSolTokenBalance((+splTokenBalance / LAMPORTS_PER_SOL).toFixed(2))
    }
    solanaConnected && isFromChainSolana && fetchTokenBalance()
  }, [solanaConnected, selectedFromChain?.value])

  const isWalletConnected = (selectedChain: number) => {
    if (evmSupportiveChains.has(selectedChain) && !isConnected) {
      toastMessage("To continue, Please connect EVM wallet.", "error")
    }
    else if (solanaSupportiveChains.has(selectedChain) && !solanaConnected) {
      toastMessage("To continue, Please connect solana wallet.", "error")
    }
  }

  return (
    <MainContainer>
      <InnerSection>
        <Flex justifyContent={"space-around"}>
          <Text
            fontFamily={theme.fonts.primary}
            fontWeight={theme.fonts.semiBold}
            fontSize={"32px"}
          >
            Bridge
          </Text>
        </Flex>
        <Flex justifyContent={"space-around"} mt={"21px"}>
          <Text
            fontFamily={theme.fonts.primary}
            fontStyle={"normal"}
            fontWeight={theme.fonts.light}
            fontSize={"14px"}
          >
            Migrate your tokens across chains
          </Text>
        </Flex>
        <Flex mt={"10px"}>
          <Flex flexDirection={"column"} width={"100%"} mt={"20px"}>
            <Select
              className="select-main-container"
              name="form-field-name"
              placeholder={"Select token"}
              options={tokens}
              onChange={(choice) => setSelectedToken(choice)}
              components={{
                Option: IconOption,
                SingleValue,
                IndicatorSeparator: () => null,
              }}
              styles={inputStyles}
            />
          </Flex>
        </Flex>
        <Flex flexWrap={"wrap"}>
          <Flex mt={"10px"} width={"50%"} flexDirection={"column"}>
            <Flex mt={"16px"} ml={"10px"} mb={"8px"}>
              <Text
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                fontSize={"14px"}
              >
                From
              </Text>
            </Flex>
            <Flex width={"90%"}>
              <Select
                isClearable
                className="select-main-container"
                name="form-field-name"
                placeholder={"Select"}
                options={supportedBlockChains.filter(
                  (x) => x.value !== selectedToChain?.value
                )}
                value={selectedFromChain}
                onChange={(choice) => { isWalletConnected(choice?.value); checkIfChainSwitchIsNeeded(choice) }}
                components={{
                  Option: IconOption,
                  SingleValue,
                  IndicatorSeparator: () => null,
                }}
                styles={inputStyles}
              />
            </Flex>
          </Flex>

          <Flex mt={"10px"} width={"50%"} flexDirection={"column"}>
            <Flex mt={"16px"} ml={"10px"} mb={"8px"}>
              <Text
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                fontSize={"14px"}
              >
                To
              </Text>
            </Flex>
            <Flex>
              <Select
                isClearable
                className="select-main-container"
                name="form-field-name"
                options={supportedBlockChains.filter(
                  (x) => x.value !== selectedFromChain?.value
                )}
                placeholder={"Select"}
                value={selectedToChain}
                onChange={(choice) => { isWalletConnected(choice?.value); setSelectedToChain(choice) }}
                components={{
                  Option: IconOption,
                  SingleValue,
                  IndicatorSeparator: () => null,
                }}
                styles={inputStyles}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex mt={"10px"}>
          <Flex flexDirection={"column"} width={"100%"}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text
                mt={"16px"}
                ml={"10px"}
                mb={"10px"}
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                fontSize={"14px"}
              >
                Token amount
              </Text>

              {isBalanceLoading && (
                <Flex mt={"15px"} mr={"10px"}>
                  <Spinner radius={7} />
                </Flex>
              )}
              {selectedFromChain?.address &&
                (transferChainBalanceData?.formatted || solTokenBalance) && (
                  <Text
                    fontFamily={theme.fonts.primary}
                    fontWeight={theme.fonts.light}
                    color={theme.colors.textDisabled}
                    fontSize={"11px"}
                    mt={"25px"}
                  >
                    {`Available: ${isFromChainSolana ? solTokenBalance :
                      +parseFloat(
                        transferChainBalanceData?.formatted
                      ).toFixed(2)
                      } 
                    tokens`}
                  </Text>
                )}
            </Flex>
            <Input
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              padding={"0px 17px"}
              className="token-amount"
              placeholder="Enter token amount"
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              value={amount}
            />
          </Flex>
        </Flex>
        <Flex flexWrap={"wrap"}>
          <Flex mt={"10px"} width={"100%"} flexDirection={"column"}>
            <Flex mt={"16px"} mb={"0px"} justifyContent={"space-between"}>
              <Text
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                fontSize={"14px"}
              >
                Required balance:
              </Text>
              <Text
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                fontSize={"14px"}
                color={
                  isValid
                    ? (isFromChainSolana ? solanaBalance : parseFloat(balanceData?.formatted)) > bridgeFee
                      ? theme.colors.success
                      : theme.colors.failure
                    : ""
                }
              >
                {isConnected && isFetchEstimates ? (
                  <Spinner radius={8} />
                ) : isConnected && isValid && bridgeEstimate ? (`${handleDecimals(+bridgeFee)} ${isFromChainSolana ? "SOL" : balanceData?.symbol}`)
                  : (
                    "-"
                  )}
              </Text>
            </Flex>
          </Flex>

          <Flex mt={"10px"} width={"100%"} flexDirection={"column"}>
            <Flex mt={"16px"} mb={"0px"} justifyContent={"space-between"}>
              <Text
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                fontSize={"14px"}
              >
                Available balance:
              </Text>
              <Text
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                color={theme.colors.textDisabled}
                fontSize={"14px"}
              >
                {isConnected && isFetchEstimates ? (
                  <Spinner radius={8} />
                ) : solanaConnected && isFromChainSolana ? solanaBalanceFormatted : isConnected && balanceData?.formatted ? (
                  `${handleDecimals(+balanceData?.formatted)} ${balanceData?.symbol
                  }`
                ) : (
                  "-"
                )}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={"21px"}>
          <Box width={"100%"}>
            <Button
              height={"44px"}
              width={"478px"}
              type={"button"}
              variant={"tertiary"}
              onClick={signTransaction}
              disabled={
                !isValid ||
                parseFloat(isFromChainSolana ? solanaBalance : balanceData?.formatted) < parseFloat(bridgeFee) ||
                isLoading ||
                isFetchEstimates
              }
              isLoading={isLoading || isTransactionOccuring || isFetchEstimates}
            >
              <Flex justifyContent={"center"}>
                <Text
                  fontWeight={theme.fonts.semiBold}
                  fontSize={"14px"}
                  ml={"6px"}
                >
                  {isLoading || isTransactionOccuring ? (
                    <Spinner radius={8} />
                  ) : (
                    "Migrate"
                  )}
                </Text>
              </Flex>
            </Button>
          </Box>
        </Flex>
      </InnerSection>
    </MainContainer>
  );
};

export default Bridge;
