import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useState } from "react";
import { SortableItem } from "./SortableItems";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { TBridgeSelection } from "config/types";
import { sortBridgesArray } from "utils";

export default function BridgeSelection({
  setBridges,
  bridges,
  disabled
}: Readonly<TBridgeSelection>) {
  const [priority, setPriority] = useState(0);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activeIndex = bridges.findIndex((item) => item.title === active.id);
      const overIndex = bridges.findIndex((item) => item.title === over.id);
      const activeIndexPriority = bridges[activeIndex].priority;
      const overIndexPriority = bridges[overIndex].priority;

      // To prevent drag over unselected element
      if (!activeIndexPriority || !overIndexPriority) {
        return;
      }

      const dummyBridges = JSON.parse(JSON.stringify(bridges));

      dummyBridges[activeIndex].priority = overIndexPriority;
      dummyBridges[overIndex].priority = activeIndexPriority;

      setBridges(sortBridgesArray(dummyBridges));
    }
  };

  const handleMove = (index: number, direction: "up" | "down") => {
    const updatedBridges = handlePriorityChange(index, direction);
    setBridges(sortBridgesArray(updatedBridges));
  };

  const handlePriorityChange = (index: number, direction: "up" | "down") => {
    const updatedBridges = [...bridges];
    const currentPriority = updatedBridges[index].priority;
    if (direction === "up") {
      const prevPriority = updatedBridges[index - 1].priority;

      // Swap priorities between the current item and the previous item in the array
      // if the previous item's priority is less than the current item's priority

      if (prevPriority < currentPriority) {
        updatedBridges[index - 1].priority = currentPriority;
        updatedBridges[index].priority = prevPriority;
      }
    } else {
      const nextPriority = updatedBridges[index + 1].priority;

      // Swap priorities between the current item and the next item in the array
      // if the next item's priority is greater than the current item's priority

      if (nextPriority > currentPriority) {
        updatedBridges[index + 1].priority = currentPriority;
        updatedBridges[index].priority = nextPriority;
      }
    }
    return updatedBridges;
  };

  const handleSelect = (checked: boolean, index: number) => {
    // const { target: { checked } } = event
    let updatedBridges = [...bridges];
    const currentPriority = updatedBridges[index].priority;
    updatedBridges[index].isSelected = checked;
    updatedBridges[index].priority = checked ? priority + 1 : 0;

    //Change the priority of every item that is selected if the current event is unselecting an item
    if (!checked) {
      updatedBridges = updatedBridges.map((bridge, i: number) => {
        if (
          bridge.isSelected &&
          bridge.priority !== 1 &&
          index !== i &&
          bridge.priority > currentPriority
        ) {
          bridge.priority = --bridge.priority;
        }
        return bridge;
      });
    }

    setBridges([...sortBridgesArray(updatedBridges)]);
    setPriority((prev) => (checked ? ++prev : --prev));
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToParentElement, restrictToVerticalAxis]}
      sensors={sensors}
    >
      <SortableContext
        items={bridges?.map((i) => i.title)}
        strategy={verticalListSortingStrategy}
      >
        {bridges.map((bridge, index) => (
          <SortableItem
            disabled={disabled || bridge.title === "Axelar"}
            handleMove={(direction: "up" | "down") =>
              handleMove(index, direction)
            }
            handleSelect={(e: any) => handleSelect(e, index)}
            checked={bridge.isSelected}
            priority={bridge.priority}
            isFirst={index === 0}
            isLast={
              index === bridges.filter((data) => data.isSelected === true).length - 1
            }
            key={bridge.title}
            id={bridge.title}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}
