import { TBridges } from "config/types"

export const supportedBridges: TBridges[] = [{
    title: "CCIP",
    isSelected: false,
    priority: 0
}, {
    title: "Wormhole",
    isSelected: false,
    priority: 0
}, {
    title: "Layerzero",
    isSelected: false,
    priority: 0
},
{
    title: "Axelar",
    isSelected: false,
    priority: 0
}
]

export enum bridgeIds {
    AXELAR = 1,
    LAYERZERO = 2,
    CCIP = 3,
    WORMHOLE = 4
}

export const BRIDGE_ID_TO_NAME = {
    [bridgeIds.AXELAR]: "Axelar",
    [bridgeIds.LAYERZERO]: "Layerzero",
    [bridgeIds.CCIP]: "CCIP",
    [bridgeIds.WORMHOLE]: "Wormhole",

}


export const BRIDGES_URL = {
    [bridgeIds.AXELAR]: process.env.REACT_APP_CHAINS_ENV === "mainnet" ? "https://axelarscan.io/gmp/" : "https://testnet.axelarscan.io/gmp/",
    [bridgeIds.LAYERZERO]: process.env.REACT_APP_CHAINS_ENV === "mainnet" ? "https://layerzeroscan.com/tx/" : "https://testnet.layerzeroscan.com/tx/",
    [bridgeIds.CCIP]: "https://ccip.chain.link/msg/",
    [bridgeIds.WORMHOLE]: "https://wormholescan.io/#/tx/",
}