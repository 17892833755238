import { Box, Flex } from "components/Box";
import { InnerSection } from "./styles";
import { Text } from "components/Text";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import useTheme from "components/hooks/useTheme";
import { EstimateResponse } from "config/types";
import {
  useAccount,
  useBalance,
  useNetwork,
  usePrepareSendTransaction,
  useSendTransaction,
  useSwitchNetwork,
} from "wagmi";
import { useEffect, useState } from "react";
import Spinner from "components/SpinnerCircle";
import { handleDecimals, toastMessage } from "utils";
import { solanaSupportiveChains } from "config/constants/chains";
import useSolanaBalance from "components/hooks/useSolanaBalance";
import {
  useSolanaConnection,
  useSolanaWallet,
} from "contexts/SolanaWalletContext";
import {
  Keypair,
  SystemProgram,
  Transaction,
  sendAndConfirmTransaction,
} from "@solana/web3.js";

interface IProp {
  deployEstimate: EstimateResponse;
  isFetchEstimates: boolean;
  isFieldValidate: any;
  existingTokenError: boolean;
  homeChainId: number;
}

const DetailCard: React.FC<IProp> = ({
  deployEstimate,
  isFetchEstimates,
  isFieldValidate,
  existingTokenError,
  homeChainId,
}) => {
  const { theme } = useTheme();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const [availableBalance, setAvailableBalance] = useState("-");
  const [requiredBalance, setRequiredBalance] = useState("-");
  const [isSufficientBalance, setIsSufficientBalance] =
    useState<boolean>(undefined);
  const [solanaTxConfirmation, setSolanaTxConfirmation] = useState<any>()
  const [isTransactionOccuring, setIsTransactionOccuring] = useState(false);

  const history = useHistory();
  const { data } = useBalance({
    address,
    chainId: deployEstimate?.chainId,
  });

  const { config } = usePrepareSendTransaction({
    request: {
      to: deployEstimate?.transaction?.to,
      value: deployEstimate?.transaction.value,
      data: deployEstimate?.transaction.data,
      gasPrice: deployEstimate?.gasFee,
    },
  });
  const { isSuccess, sendTransaction, error } = useSendTransaction(config);

  useEffect(() => {
    if (isSuccess || solanaTxConfirmation) {
      setIsTransactionOccuring(false)
      history.push(`/details?flow=token&salt=${deployEstimate?.salt}`);
    }
    if (error) {
      setIsTransactionOccuring(false)
      toastMessage(error.message, "error");
    }
  }, [isSuccess, solanaTxConfirmation, error]);

  const { connection: solanaConnection } = useSolanaConnection();
  const {
    sendTransaction: sendSolanaTx,
    signTransaction: signSolanaTx,
  } = useSolanaWallet();
  const { solanaBalance, solanaBalanceFormatted } = useSolanaBalance();

  const checkAndSendTransaction = async () => {
    if (!solanaSupportiveChains.has(homeChainId)) {
      if (chain.id !== deployEstimate?.chainId) {
        setIsTransactionOccuring(true)
        const network = await switchNetworkAsync(deployEstimate?.chainId)
          .then((res) => {
            sendTransaction?.();
          })
          .catch((err) => {
            console.log("Error while changing network");
            setIsTransactionOccuring(false)
          });
      } else {
        sendTransaction?.();
        setIsTransactionOccuring(true)
      }
    } else {
      setIsTransactionOccuring(true)
      try {
        const {
          context: { slot: minContextSlot },
          value: { blockhash, lastValidBlockHeight },
        } = await solanaConnection.getLatestBlockhashAndContext();

        const tx = Transaction.from(
          Buffer.from(deployEstimate.transaction, "base64")
        );
        // const sign = await signSolanaTx(deployEstimate.transaction);

        const signature = await sendSolanaTx(tx, solanaConnection);

        const confirmedTx = await solanaConnection.confirmTransaction({
          blockhash,
          lastValidBlockHeight,
          signature,
        });

        if (confirmedTx) {
          setSolanaTxConfirmation(confirmedTx)
          setIsTransactionOccuring(false)
        }
      } catch (error: any) {
        setIsTransactionOccuring(false)
        toastMessage("User rejected the transaction.", "error");

      }
    }
  };

  useEffect(() => {
    (async () => {
      if (homeChainId) {
        if (solanaSupportiveChains.has(homeChainId)) {
          +solanaBalance > 0 ?
            setAvailableBalance(solanaBalanceFormatted) : setAvailableBalance("0.00 SOL");
        } else {
          const balance = data?.formatted
            ? `${handleDecimals(+data?.formatted)} ${data?.symbol}`
            : "-";

          setAvailableBalance(balance);
        }
      }
    })();
  }, [homeChainId, solanaBalance, data]);

  useEffect(() => {
    if (homeChainId && deployEstimate) {
      if (!solanaSupportiveChains.has(homeChainId)) {
        if (
          deployEstimate?.totalGasFeeInTokenMintingChain &&
          isFieldValidate() &&
          existingTokenError === false &&
          data?.symbol
        ) {
          setRequiredBalance(
            `${handleDecimals(
              deployEstimate?.totalGasFeeInTokenMintingChain
            )} ${data?.symbol}`
          );
          if (
            deployEstimate?.totalGasFeeInTokenMintingChain <= +data?.formatted
          ) {
            setIsSufficientBalance(true);
          } else {
            setIsSufficientBalance(false);
          }
        }
      } else {
        if (
          deployEstimate?.totalGasFeeInTokenMintingChain &&
          isFieldValidate() &&
          existingTokenError === false
        ) {
          setRequiredBalance(
            `${handleDecimals(
              deployEstimate?.totalGasFeeInTokenMintingChain
            )} SOL`
          );

          if (deployEstimate?.totalGasFeeInTokenMintingChain <= solanaBalance) {
            setIsSufficientBalance(true);
          } else {
            setIsSufficientBalance(false);
          }
        }
      }
    }
  }, [deployEstimate, homeChainId]);

  return (
    <Flex mt={"73px"} ml={"10px"}>
      <InnerSection>
        <Flex flexDirection={"column"}>
          <Flex>
            <Text
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.medium}
              fontSize={"20px"}
            >
              Enter details and deploy
            </Text>
          </Flex>
          <Flex mt={"20px"} justifyContent={"space-between"}>
            <Text
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.light}
              fontSize={"14px"}
            >
              Required balance:
            </Text>
            <Text
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.light}
              fontSize={"14px"}
              color={
                isSufficientBalance !== undefined
                  ? isSufficientBalance === true
                    ? theme.colors.success
                    : theme.colors.error
                  : ""
              }
            >
              {isFetchEstimates ? <Spinner radius={8} /> : requiredBalance}
            </Text>
          </Flex>
          <Flex mt={"10px"} justifyContent={"space-between"}>
            <Text
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.light}
              fontSize={"14px"}
            >
              Available balance:
            </Text>
            <Text
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.light}
              color={theme.colors.textDisabled}
              fontSize={"14px"}
            >
              {isFetchEstimates ? <Spinner radius={8} /> : availableBalance}
            </Text>
          </Flex>
          <Flex justifyContent={"space-around"} mt={"45px"}>
            <Box>
              <Button
                disabled={
                  isSufficientBalance === false ||
                  deployEstimate?.totalGasFeeInTokenMintingChain ===
                  undefined ||
                  !isFieldValidate() ||
                  isFetchEstimates ||
                  existingTokenError
                }
                height={"44px"}
                width={"306px"}
                type={"submit"}
                variant={"tertiary"}
                onClick={checkAndSendTransaction}
              >
                <Flex justifyContent={"center"}>
                  <Text
                    fontWeight={theme.fonts.semiBold}
                    fontSize={"14px"}
                    ml={"6px"}
                  >
                    {isTransactionOccuring ? <Spinner radius={8} /> : "Deploy"}
                  </Text>
                </Flex>
              </Button>
            </Box>
          </Flex>
        </Flex>
      </InnerSection>
    </Flex>
  );
};

export default DetailCard;
