import useTheme from "components/hooks/useTheme";
import { Text } from "components/Text";
import { Box, Flex } from "components/Box";
import {
  ChianCol,
  Input,
  MainContainer,
  Switch,
  SwitchToggle,
} from "./styles";
import { useEffect, useMemo, useState } from "react";
import { ChainArray, inputStyles } from "config/constants";
import Select, { components } from "react-select";
import { useLocation } from "react-router-dom";
import DetailCard from "./DetailCard";
import { getDeployEstimates, getTokenInfo } from "services/token.service";
import { useAccount, useFeeData, useNetwork, useSwitchNetwork } from "wagmi";
import {
  CATType,
  EstimateResponse,
  IChainOption,
  TBridges,
  TokenInfoResponse,
} from "config/types";
import { increaseGasFee, noExponents, sortBridgesArray, toastMessage } from "utils";
import DragableBridges from "../../../components/BridgeSelection";
import { BRIDGE_ID_TO_NAME, bridgeIds, supportedBridges } from "config/constants/supportedBridges";
import { BigNumber } from "ethers";
import { useSolanaWallet } from "contexts/SolanaWalletContext";
import { SupportedChainId, solanaSupportiveChains } from "config/constants/chains";

const IconOption = (props) => {
  const { Option } = components;
  return (
    <Option className="chain-options" {...props}>
      <Box className="svg-icon" mr={"5px"}>
        {props.data.icon}
      </Box>
      {props.data.label}
    </Option>
  );
};

const SingleValue = (props) => (
  <Flex alignItems={"center"}>
    <Box className="select-svg-icon" mt={"5px"} mr={"5px"}>
      {props.data.icon}
    </Box>
    {props.data.label}
  </Flex>
);

const DeployTokens = () => {
  const { theme } = useTheme();
  const { address: evmWalletAddress, isConnected } = useAccount();
  const { chain } = useNetwork();

  const [multipleChains, setMultipleChains] = useState(() =>
    ChainArray.map((item) => ({
      ...item,
      isChecked: false,
      isHomeChain: false,
      isAlreadyDeployed: false,
    }))
  );
  const [isFetchEstimates, setIsFetchEstimates] = useState<boolean>(false);
  const [homeChain, setHomeChain] = useState<IChainOption>(null);
  const [tokenName, setTokenName] = useState<string | undefined>(undefined);
  const [tokenTicker, setTokenTicker] = useState<string | undefined>(undefined);
  const [tokenDecimals, setTokenDecimals] = useState<string | undefined>(undefined);
  const [tokenSupply, setTokenSupply] = useState<string | undefined>(undefined);
  const [tokenAdress, setTokenAdress] = useState<string | undefined>(undefined);
  const [isError, setIsError] = useState(false);
  const [deployEstimates, setDeployEstimates] = useState<EstimateResponse>(null);
  const [tokenSalt, setTokenSalt] = useState<string>("");
  const { data: gasFee } = useFeeData({ chainId: homeChain?.value });
  const [bridges, setBridges] = useState<Array<TBridges>>(supportedBridges.map((tokenBridges) => ({ ...tokenBridges })));
  const { connected: solanaConnected, publicKey } = useSolanaWallet();
  const { switchNetworkAsync } = useSwitchNetwork();

  const selectedBriges = useMemo(
    () =>
      bridges.filter((selectedBriges) => selectedBriges.isSelected === true)
        .length > 0,
    [bridges]
  );

  const location = useLocation();
  const newToken: string = new URLSearchParams(location.search).get("newToken");
  const [catType, setCatType] = useState<string>(
    newToken === "true" ? CATType.CATType : ""
  );
  const options: Array<IChainOption> = ChainArray.map((item) => {
    return { value: item.chainId, label: item.name, icon: item.icon };
  });

  useEffect(() => {
    if (
      multipleChains.length > 0 &&
      tokenDecimals &&
      tokenSupply &&
      selectedBriges
    ) {
      fetchDeployEstimates();
    }
  }, [multipleChains, tokenDecimals, tokenSupply, bridges, isConnected, solanaConnected]);

  useEffect(() => {
    if (chain?.id && solanaConnected) return;
    if (chain?.id) {
      const selectedOpt = options.find((x) => x.value === chain?.id);
      if (selectedOpt) setChain(selectedOpt);
    }
    if (solanaConnected) {
      const selectedOpt = options.find((x) => x.value === SupportedChainId.SOLANA || x.value === SupportedChainId.SOLANA_DEV_NET);
      if (selectedOpt) setChain(selectedOpt);
    }
  }, [chain, solanaConnected]);

  useEffect(() => {

    if (newToken === "false" && tokenAdress && homeChain?.value != null) {
      fetchTokenInfo();
    }
  }, [newToken, tokenAdress, homeChain]);

  const setTokenSupplyInWei = (
    tknSupply: string,
    tknDecimal: string,
    isCallFromSupply: boolean
  ) => {
    const decimals = parseFloat(tknDecimal);
    if (tknSupply) {
      const supply = noExponents(
        Math.floor(+tknSupply * Math.pow(10, decimals)).toString()
      );
      setTokenSupply(supply);
    } else if (isCallFromSupply) {
      setTokenSupply("");
    }
  };

  const solanaChain = useMemo(() => (multipleChains?.findIndex((res) => res.isChecked === true && res.isSolana === true) > -1), [multipleChains])
  const evmChain = useMemo(() => (multipleChains?.findIndex((res) => res.isChecked === true && res.isSolana === false) > -1), [multipleChains])

  const isFieldValidate = () => {
    if (((solanaChain && evmChain) ? (solanaConnected && isConnected) : evmChain ? isConnected : solanaConnected) &&
      homeChain !== null &&
      multipleChains.length > 0 &&
      multipleChains.filter((item) => !item.isAlreadyDeployed && item.isChecked)
        .length > 0 &&
      tokenName &&
      tokenTicker &&
      tokenDecimals &&
      (newToken == "true" ? +tokenSupply > 0 : tokenSupply) &&
      selectedBriges
    ) {
      return true;
    }
    setDeployEstimates(null);
    return false;
  };

  const [apiQueue, setApiQueue] = useState([]);
  const [existingTokenError, setExistingTokenError] = useState(false);

  const enqueueApiCall = async (request) => {
    setApiQueue((prevQueue) => [...prevQueue, request]);
  };

  const processApiQueue = async () => {
    if (isFetchEstimates || apiQueue.length === 0) return;

    const apiRequest = apiQueue[0];
    setIsFetchEstimates(true);

    const protocolPriorities = bridges
      .map((bridge: TBridges) => {
        if (bridge.isSelected) {
          return bridgeIds[bridge.title.toUpperCase()].toString();
        }
      })
      .filter((b: number | undefined) => b !== undefined);

    try {
      const res: EstimateResponse = await apiRequest(
        tokenName,
        tokenTicker,
        parseFloat(tokenDecimals),
        tokenSupply,
        filteredChains,
        homeChain,
        catType,
        evmWalletAddress,
        publicKey?.toString(),
        tokenSalt,
        tokenAdress,
        protocolPriorities
      );
      if (!solanaSupportiveChains.has(homeChain?.value)) {
        res.gasFee = increaseGasFee(gasFee?.gasPrice, 10);
      }

      setTokenSalt(res?.salt);
      setDeployEstimates(res);

      setApiQueue((prevQueue) => prevQueue.slice(1));
    } catch (error: any) {
      toastMessage(error.message, "error")
      console.error("Error occurred while making API call:", error.message);
      setDeployEstimates(null);
    }
    setIsFetchEstimates(false);
  };

  useEffect(() => {
    processApiQueue();
  }, [apiQueue]);

  const [filteredChains, setFilteredChains] = useState([]);
  const fetchDeployEstimates = async () => {
    if (isFieldValidate()) {
      const chainStringMapping = multipleChains
        .filter((x) => x.isChecked === true && x.isAlreadyDeployed === false)
        .map((item) => {
          return item.chainId.toString();
        });

      if (chainStringMapping.length > 0) {
        setFilteredChains(chainStringMapping);
        enqueueApiCall(getDeployEstimates);
      }
    }
  };

  const setChain = (item) => {
    setHomeChain(item);
    const hasHomeChain = multipleChains.find((x) => x.isHomeChain === true);
    if (hasHomeChain) {
      hasHomeChain.isChecked = false;
      hasHomeChain.isHomeChain = false;
    }

    const selectedChain = multipleChains.find((x) => x.chainId === item?.value);
    selectedChain.isChecked = true;
    selectedChain.isHomeChain = true;

    setMultipleChains((prev) => [...multipleChains]);
  };

  const fetchBridgesPriorities = async (protocolPriority) => {
    try {
      let bridgesCopy = [...bridges]
      protocolPriority.forEach((p: BigNumber, index: number) => {
        const bridgeId = parseInt(p.toString())
        const bridgeName = BRIDGE_ID_TO_NAME[bridgeId]
        const foundIndex = bridgesCopy.findIndex((b: TBridges) => b.title === bridgeName)
        if (foundIndex !== -1) {
          bridgesCopy[foundIndex] =
          {
            title: bridgeName,
            isSelected: true,
            priority: index + 1
          }
        }
      }
      )
      setBridges([...sortBridgesArray(bridgesCopy)])
    } catch (error: any) {
      console.error(error.message)
    }
  }

  const fetchTokenInfo = async () => {
    try {
      const res: TokenInfoResponse = await getTokenInfo(
        homeChain.value,
        tokenAdress
      );
      const { tokenInfo, type, deployedNetworks } = res;
      setTokenName(tokenInfo.name);
      if (newToken === "true") {
        setCatType(CATType.CATType);
      } else {
        if (deployedNetworks?.length && tokenInfo?.protocolPriority?.length) {
          fetchBridgesPriorities(tokenInfo?.protocolPriority)
          setCatType("CATType");
        } else {
          setBridges(supportedBridges.map((tokenBridges) => ({ ...tokenBridges })))
          setCatType(type);
        }
      }
      setTokenDecimals(tokenInfo.decimals.toString());
      setTokenSupply("0");
      setTokenTicker(tokenInfo.symbol);
      setTokenSalt(tokenInfo?.salt);
      const chains = multipleChains.map((chain) => {
        chain.isAlreadyDeployed = false;
        if (res.deployedNetworks.includes(chain.chainId)) {
          chain.isAlreadyDeployed = true;
          chain.isChecked = true;
        }
        return chain;
      });

      setExistingTokenError(false);
      setMultipleChains(chains);
    } catch (error) {
      setExistingTokenError(true);
      toastMessage("Address not found on chain.", "error")
      setBridges(supportedBridges.map((tokenBridges) => ({ ...tokenBridges })))
    }
  };

  const handleTokenAddress = (e) => {
    const isHomeChainSol = solanaSupportiveChains.has(homeChain?.value)
    const tokenAddressRegex = isHomeChainSol ? /[1-9A-HJ-NP-Za-km-z]{44}/g : /^0x[a-fA-F0-9]{40}$/g
    if (tokenAddressRegex.test(e.target.value) || e.target.value === "") {
      // reseting the chains
      setMultipleChains(() => ChainArray.map((item: any) => ({
        ...item,
        isChecked: homeChain?.value === item.chainId ? true : false,
        isHomeChain: homeChain?.value === item.chainId ? true : false,
        isAlreadyDeployed: homeChain?.value === item.chainId ? true : false,
      })))
      setBridges(supportedBridges.map((tokenBridges) => ({ ...tokenBridges })))

      setTokenAdress(e.target.value);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const isWalletConnected = (chain: any) => {
    if (!chain.isChecked) {
      return
    }
    if (chain.isSolana) {
      if (!solanaConnected) {
        toastMessage("To continue, Please connect solana wallet.", "error")
      }
    } else if (!isConnected) {
      toastMessage("To continue, Please connect EVM wallet.", "error")
    }
  }

  const checkSwitchNetwork = async (choice) => {
    if (!solanaSupportiveChains.has(choice?.value) && chain && choice && chain?.id !== choice?.value) {
      try {
        await switchNetworkAsync(choice.value);
      } catch (error) {
        toastMessage("User rejected the transaction.", "error");
        return;
      }
    }
    setChain(choice);
  }

  return (
    <MainContainer>
      <Flex flexDirection={"row"}>
        <Flex flexDirection={"column"} width={"75%"}>
          <Flex mt={"30px"}>
            <Text
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.semiBold}
              fontSize={"32px"}
              lineHeight={"45px"}
            >
              {newToken === "true"
                ? "Deploy new token"
                : "Deploy existing token"}
            </Text>
          </Flex>

          <Flex mt={"48px"} flexDirection={"column"}>
            <Text
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.medium}
              fontSize={"16px"}
            >
              Blockchains
            </Text>
            <Flex mt={"16px"} ml={"14px"} mb={"8px"}>
              <Text
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                fontSize={"14px"}
              >
                Select home chain
              </Text>
            </Flex>
            <Flex>
              <Select
                required={true}
                className="select-main-container"
                name="homeChain"
                placeholder={"Select"}
                value={homeChain}
                options={options}
                onChange={(item: any) => {
                  checkSwitchNetwork(item)
                }}
                components={{
                  SingleValue,
                  Option: IconOption,
                  IndicatorSeparator: () => null,
                }}
                styles={inputStyles}
              />
            </Flex>
          </Flex>
          {newToken === "true" && (
            <Flex mt={"32px"} flexDirection={"column"}>
              <Text
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.medium}
                fontSize={"16px"}
              >
                Token information
              </Text>
              <Flex flexWrap={"wrap"}>
                <Flex flexDirection={"column"} width={"48%"} pr={"24px"}>
                  <Text
                    mt={"16px"}
                    ml={"15px"}
                    mb={"8px"}
                    fontFamily={theme.fonts.primary}
                    fontWeight={theme.fonts.light}
                    fontSize={"14px"}
                  >
                    Token name
                  </Text>
                  <Flex flexDirection={"column"}>
                    <Input
                      type="text"
                      padding={"0px 17px 0px 15px"}
                      placeholder="Token name"
                      className={tokenName === "" && "border-color"}
                      onBlur={(e) => {
                        setTokenName(e.target.value);
                      }}
                    />
                    {/* {tokenName === "" && (
                      <Text
                        fontFamily={theme.fonts.primary}
                        fontWeight={theme.fonts.light}
                        fontSize={"12px"}
                        color={theme.colors.error}
                      >
                        Invalid value
                      </Text>
                    )} */}
                  </Flex>
                </Flex>
                <Flex flexDirection={"column"} width={"48%"}>
                  <Text
                    mt={"16px"}
                    ml={"15px"}
                    mb={"8px"}
                    fontFamily={theme.fonts.primary}
                    fontWeight={theme.fonts.light}
                    fontSize={"14px"}
                  >
                    Token ticker
                  </Text>
                  <Input
                    type="text"
                    padding={"0px 17px 0px 15px"}
                    placeholder="Token ticker"
                    className={tokenTicker === "" && "border-color"}
                    onBlur={(e) => {
                      setTokenTicker(e.target.value);
                    }}
                  />
                </Flex>
                <Flex flexDirection={"column"} width={"48%"} pr={"24px"}>
                  <Text
                    mt={"16px"}
                    ml={"15px"}
                    mb={"8px"}
                    fontFamily={theme.fonts.primary}
                    fontWeight={theme.fonts.light}
                    fontSize={"14px"}
                  >
                    Token decimals
                  </Text>
                  <Input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    padding={"0px 17px 0px 15px"}
                    placeholder="Enter value ( 1 - 9 )"
                    className={tokenDecimals === "" && "border-color"}
                    onChange={(e) => {
                      if (!(/^[1-9]$/.test(e.target.value))) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }}
                    onBlur={(e) => {
                      setTokenDecimals(e.target.value);
                      setTokenSupplyInWei(tokenSupply, e.target.value, false);
                    }}
                  />
                </Flex>
                <Flex flexDirection={"column"} width={"48%"}>
                  <Text
                    mt={"16px"}
                    ml={"15px"}
                    mb={"8px"}
                    fontFamily={theme.fonts.primary}
                    fontWeight={theme.fonts.light}
                    fontSize={"14px"}
                  >
                    Token supply
                  </Text>
                  <Input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    padding={"0px 17px 0px 15px"}
                    placeholder="Enter amount"
                    className={tokenSupply === "" && "border-color"}
                    onBlur={(e) =>
                      setTokenSupplyInWei(e.target.value, tokenDecimals, true)
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
          {newToken === "false" && (
            <Flex flexDirection={"column"} mt={"32px"} pr={"20px"}>
              <Text
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.medium}
                fontSize={"16px"}
              >
                Token information
              </Text>
              <Text
                mt={"16px"}
                ml={"15px"}
                mb={"8px"}
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                fontSize={"14px"}
              >
                Token address
              </Text>
              <Input
                type="text"
                padding={"0px 17px 0px 15px"}
                placeholder="Token address"
                className={isError && "border-color"}
                onChange={(e) => {
                  handleTokenAddress(e);
                }}
              />
            </Flex>
          )}
          <Flex mt={"32px"} flexDirection={"column"} mb={"28px"}>
            <Text
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.medium}
              fontSize={"16px"}
            >
              Select additional chains{" "}
            </Text>
            <Flex flexWrap={"wrap"} flexDirection={"row"} mt={"28px"}>
              {multipleChains?.map((chain, index) => {
                return (
                  <ChianCol
                    key={index}
                    style={{ opacity: chain.isAlreadyDeployed ? 0.6 : 1 }}
                  >
                    <Flex alignItems={"center"}>
                      {chain?.icon}
                      <Text fontFamily={theme.fonts.primary}>{chain.name}</Text>
                    </Flex>
                    <Flex alignItems={"center"}>
                      {chain.isHomeChain && (
                        <Text
                          fontFamily={theme.fonts.primary}
                          fontSize={"10px"}
                        >
                          HOME CHAIN
                        </Text>
                      )}
                      <Box>
                        <Switch
                          isDisabled={
                            chain.isAlreadyDeployed || chain.isHomeChain
                          }
                          isChecked={chain.isChecked}
                          onKeyPress={() => {
                            if (
                              chain.isAlreadyDeployed === false &&
                              chain.isHomeChain === false
                            ) {
                              multipleChains[index].isChecked =
                                !chain.isChecked;
                              setMultipleChains((item) => [...multipleChains]);
                            }
                          }}
                          onClick={() => {
                            if (
                              chain.isAlreadyDeployed === false &&
                              chain.isHomeChain === false
                            ) {
                              multipleChains[index].isChecked =
                                !chain.isChecked;
                              setMultipleChains((item) => [...multipleChains]);
                            }
                            isWalletConnected(chain)
                          }}
                        >
                          <SwitchToggle state={!chain.isChecked} />
                          <SwitchToggle state={chain.isChecked} />
                        </Switch>
                      </Box>
                    </Flex>
                  </ChianCol>
                );
              })}
            </Flex>
          </Flex>
          <Flex flexDirection={"column"} mb="15%" pr="20px">
            <Text
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.medium}
              fontSize={"16px"}
            >
              Select and arrange bridges for priority
            </Text>
            <DragableBridges disabled={newToken !== "true" && catType === "CATType"} setBridges={setBridges} bridges={bridges} />
          </Flex>
        </Flex>
        <DetailCard
          deployEstimate={deployEstimates}
          isFetchEstimates={isFetchEstimates}
          isFieldValidate={isFieldValidate}
          existingTokenError={existingTokenError}
          homeChainId={homeChain?.value || null}
        />
      </Flex>
    </MainContainer>
  );
};

export default DeployTokens;
