import { RoutingEngineEvents } from "config/abi/catERC721";
import { EVM_CHAIN_ID_TO_TICKER_AXELAR, EVM_CHAIN_TO_AXELAR_NAMES } from "config/constants/chains";
import { BRIDGES_URL } from "config/constants/supportedBridges";
import { BigNumber } from "ethers";
import { getAxelarEstimatedFee, getExplorerLink } from "services/explorer.service";
import Web3 from "web3";

export const getAxelarFee = async (estimatedWei: BigNumber, bridgeFromChain: number, bridgeToChain: number) => {
    try {
        let {
            data: { fee: axelarFee },
        }: any = await getAxelarEstimatedFee(
            EVM_CHAIN_TO_AXELAR_NAMES[bridgeFromChain],
            EVM_CHAIN_TO_AXELAR_NAMES[bridgeToChain],
            EVM_CHAIN_ID_TO_TICKER_AXELAR[bridgeFromChain]
        );
        if (!axelarFee) {
            return estimatedWei;
        }
        axelarFee = BigNumber.from(axelarFee).add(
            BigNumber.from(axelarFee).mul(10).div(100)
        );
        return BigNumber.from(axelarFee).gt(estimatedWei)
            ? axelarFee
            : estimatedWei;
    } catch (error) {
        return estimatedWei;
    }
};

type TReturnListenToLogs = { txLink: string, status: boolean, hash: string }

export const ListenToLogs = async (tx: any, estimates: any, provider: any): Promise<TReturnListenToLogs> => {
    let eventABI = RoutingEngineEvents.find(
        (event: any) => event.name === "protocolSendEvent"
    )?.inputs;
    const web3 = new Web3(provider.connection.url);

    let txLink: string = ""
    let hash: string = ""
    let status: boolean = false
    for (let i = 0; i < tx?.logs?.length; i++) {
        const log = tx.logs[i]
        try {
            const events = web3.eth.abi.decodeLog(
                eventABI,
                log.data.toString(),
                log.topics.map((log: any) => log.toString())
            );

            const bridgeID = events.protocolId.toString().replace("n", "");
            const chainURL = BRIDGES_URL[bridgeID];
            if (bridgeID === "4") {
                hash = estimates.hash
                txLink = `${chainURL}${estimates.hash.substring(
                    2
                )}${process.env.REACT_APP_CHAINS_ENV === "testnet" ? "?network=TESTNET" : ""}`;
            }
            else if (bridgeID === "3") {
                const response = await getExplorerLink("ccip", estimates.hash);
                hash = response.data.url
                txLink = `${chainURL}${response.data.url}`;
            } else {
                hash = estimates.hash
                txLink = `${chainURL}${estimates.hash}`;
            }
            status = false
        } catch (error) {
            console.log(error)
        }
    };
    return { txLink, status, hash };
};